import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import kebabCase from 'lodash/kebabCase';

// Components
import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SideBar from '../components/sidebar';

const TagsPage = ({ data: { allMarkdownRemark: { group }, site: { siteMetadata: { title } } } }) => (
	<Layout>
		<SEO description="Tags page" title="Tags" />
		<SideBar />
		<div className="content posts-container">
			<h1 className="page-title">Tags</h1>
			<hr className="hr-style" />
			<div className="tag-list">
				{group.map((tag) => (
					
					<Link key={tag.fieldValue} to={`/tags/${kebabCase(tag.fieldValue)}/`}>
						
						{tag.fieldValue} ({tag.totalCount})
					</Link>
				))}
			</div>
		</div>
	</Layout>
);

TagsPage.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			group: PropTypes.arrayOf(
				PropTypes.shape({
					fieldValue: PropTypes.string.isRequired,
					totalCount: PropTypes.number.isRequired
				}).isRequired
			)
		}),
		site: PropTypes.shape({
			siteMetadata: PropTypes.shape({
				title: PropTypes.string.isRequired
			})
		})
	})
};

export default TagsPage;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(limit: 2000, sort: { fields: [frontmatter___date], order: ASC }) {
			group(field: frontmatter___tags) {
				fieldValue
				totalCount
			}
		}
	}
`;
